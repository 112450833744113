import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { useCheckout } from 'src/hooks/useCheckout';

export const GenericForm = ({ children }: { children: ReactNode }) => {
  const { handleSubmit } = useForm();
  const { handleSubmitPaymentMethodForm } = useCheckout();

  return (
    <form
      className="w-full h-full py-4 lg:p-8 px-0"
      onSubmit={handleSubmit(handleSubmitPaymentMethodForm)}
    >
      {children}
    </form>
  );
};
