import dynamic from 'next/dynamic';
import { useEffect, useMemo, useRef } from 'react';

import { useCheckout } from 'src/hooks/useCheckout';
import { BoletoBancario } from './BoletoBancario';
import { Dinheiro } from './Dinheiro';
import { Deuna } from './Deuna';
import { PaymentMethodItem } from './PaymentMethodItem';
import { Pix } from './Pix';

import BoletoSvg from '@assets/icons/boleto-bancario.svg';
import CreditCardSvg from '@assets/icons/credit-card.svg';
import MoneySvg from '@assets/icons/money.svg';
import PixSvg from '@assets/icons/pix.svg';
import { Alert, Button } from '@components/common';
import { Accordion } from '@components/common/Accordion';
import { useCart, useGTMDataLayer } from '@hooks';
import { configuredVariant } from '@utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import {
  getBreadcrumbCategory,
  isProductConfigurable,
  useCustomAttributes
} from 'src/hooks/useProductFullDetail';

const WireCard = dynamic(() => import('./WireCard').then(mod => mod.WireCard), {
  ssr: false
});

export const paymentMethodsMap = {
  backoffice_cc: {
    icon: MoneySvg,
    content: Dinheiro
  },
  checkmo: {
    icon: MoneySvg,
    content: Dinheiro
  },
  pagbank_paymentmagento_boleto: {
    icon: BoletoSvg,
    content: BoletoBancario
  },
  // pagarme_creditcard: {
  //   icon: CreditCardSvg,
  //   content: () => null
  // },
  pagbank_paymentmagento_pix: {
    icon: PixSvg,
    content: Pix
  },
  pagbank_paymentmagento_cc: {
    icon: CreditCardSvg,
    content: WireCard
  },
  deuna_paymentlink: {
    icon: CreditCardSvg,
    content: Deuna
  }
};

export const PaymentMethods = ({
  onOpen,
  selectedMethod,
  setSelectedMethod
}) => {
  const { isSignature, cart } = useCart();
  const {
    paymentMethodList,
    paymentMethodAccordionRef,
    paymentMethodCodeHandler,
    paymentMethodCodeSelected,
    refetchPaymentMethods
  } = useCheckout();
  const pageViewControl = useRef(false);
  const selectedPaymentMethodControl = useRef('');
  const { pushToDataLayer } = useGTMDataLayer();

  const paymentMethodItemRender = useMemo(() => {
    const foundItem = paymentMethodsMap[selectedMethod.code];

    if (foundItem) {
      const Component = foundItem.content;
      return (
        <Component expiration={selectedMethod?.additional_info?.expiration} />
      );
    }

    return <></>;
  }, [selectedMethod]);

  const {
    getProductCustomAttributes,
    loading: isCustomAttributesLoading,
    metadata: customAttributesMetadata
  } = useCustomAttributes();

  const cartDetails = useMemo(() => {
    return isCustomAttributesLoading ||
      isEmpty(customAttributesMetadata) ||
      !cart
      ? null
      : {
          ...cart,
          items: cart?.items?.map(item => {
            const configured_variant =
              configuredVariant(item?.configurable_options, item?.product) ||
              item?.product;

            const custom_attributes =
              getProductCustomAttributes(configured_variant);

            const isConfigurable =
              item?.product.__typename && isProductConfigurable(item?.product);

            return {
              ...item,
              product: {
                parent_sku: isConfigurable ? item?.product?.sku : null,
                is_configurable: isConfigurable,
                ...item?.product,
                ...configured_variant,
                category: getBreadcrumbCategory(item?.product?.categories),
                ...custom_attributes
              }
            };
          })
        };
  }, [
    cart,
    customAttributesMetadata,
    getProductCustomAttributes,
    isCustomAttributesLoading
  ]);

  useEffect(() => {
    refetchPaymentMethods();
  }, [refetchPaymentMethods]);

  /**
   * This effect selects the first payment method when the component is
   * loaded and there is only one item available
   */
  useEffect(() => {
    if (!selectedMethod?.code && paymentMethodList?.length > 0) {
      const method = paymentMethodList[0];
      setSelectedMethod(method);
      paymentMethodCodeHandler(method?.code as string);
    }
  }, [
    paymentMethodCodeHandler,
    paymentMethodList,
    selectedMethod?.code,
    setSelectedMethod
  ]);

  useEffect(() => {
    if (!pageViewControl.current) {
      pushToDataLayer('checkoutPagePayment', {});
      pageViewControl.current = true;
    }
  }, [pageViewControl, pushToDataLayer]);

  useEffect(() => {
    if (
      selectedMethod &&
      paymentMethodCodeSelected &&
      !isEmpty(paymentMethodCodeSelected) &&
      (!selectedPaymentMethodControl.current ||
        selectedPaymentMethodControl.current !== paymentMethodCodeSelected)
    ) {
      pushToDataLayer('checkoutPagePayment', {
        step: 4,
        pageType: 'checkoutPagePayment',
        option: 'Pagamento selecionado',
        additional_info: selectedMethod,
        ...cartDetails
      });

      selectedPaymentMethodControl.current = paymentMethodCodeSelected;
    }
  }, [paymentMethodCodeSelected, pushToDataLayer, selectedMethod, cartDetails]);

  return (
    <Accordion
      id="payment-method-accordion"
      label="Pagamento"
      value={selectedMethod.title}
      ref={paymentMethodAccordionRef}
      onOpen={onOpen}
    >
      {isSignature && (
        <Alert
          text="Para compra programada só é aceito o pagamento por cartão de crédito à vista."
          highlight="Compra programada"
        />
      )}
      <div className="pt-0 lg:pt-4 p-4 w-full lg:flex">
        <div className="lg:w-2/6">
          {paymentMethodList.map((item, index) => (
            <PaymentMethodItem
              key={index}
              value={item.code}
              label={item.title}
              inputId={item.code}
              name="payment-methods"
              currentValue={selectedMethod?.code}
              onChange={val => {
                setSelectedMethod(item);
                paymentMethodCodeHandler(val as string);
              }}
              icon={paymentMethodsMap[item.code]?.icon}
            >
              <div className="lg:h-[120px] w-full border-t-[1px] border-t-[#F5F5F5] lg:hidden">
                {paymentMethodItemRender}
              </div>
            </PaymentMethodItem>
          ))}
        </div>

        {!selectedMethod?.code && (
          <div className="block mt-4 lg:hidden">
            <Button className="w-full" disabled>
              Continuar
            </Button>
          </div>
        )}
        <div
          className={classNames(
            'w-full hidden lg:block bg-[#FCFCFC]',
            Object.entries(selectedMethod)?.length > 0 &&
              'border-primary-light border'
          )}
        >
          {paymentMethodItemRender}
        </div>
      </div>
    </Accordion>
  );
};
