import classNames from 'classnames';
import { ReactFragment, ReactNode, useCallback, useEffect } from 'react';

/**
 * Used to hide the left border of the payment form container
 */
const checkedAfterStyle =
  'after:content-[""] after:absolute after:h-full after:w-[1px] after:bg-white after:right-0 after:top-0 after:bottom-0 after:translate-x-[1px] after:z-10';

interface Props {
  inputId: string;
  name: string;
  currentValue: string | number;
  value: string | number;
  onChange(value: string | number): void;
  label: string;
  children?: ReactNode | ReactFragment;
  icon?: () => ReactNode;
}

export const PaymentMethodItem = ({
  currentValue,
  value,
  inputId,
  name,
  onChange,
  label,
  icon,
  children
}: Props) => {
  const checked = useCallback(() => {
    return currentValue === value;
  }, [currentValue, value]);

  useEffect(() => {
    const element = document.getElementById(inputId + '-container');
    if (!element) return;
    if (checked()) {
      element.previousElementSibling?.classList.add('lg:border-b-0');
    } else {
      element.previousElementSibling?.classList.remove('lg:border-b-0');
    }
  }, [checked, inputId]);

  return (
    <div
      id={inputId + '-container'}
      className={classNames(
        'w-full border-b lg:first-of-type:border-t pt-4 relative cursor-pointer lg:py-8',
        checked()
          ? 'border-gray-lightest lg:border-primary-light bg-[#FCFCFC] lg:border-t lg:border-t-primary-light' +
              checkedAfterStyle
          : 'border-gray-lightest lg:border-gray-light'
      )}
    >
      <div
        onClick={() => onChange(value)}
        className="flex items-center pb-2 lg:pb-0"
      >
        <div className="mr-1">
          <input
            type="radio"
            id={inputId}
            name={name}
            value={value}
            checked={checked()}
            onChange={() => {}}
            style={{ display: 'none' }}
          />

          <span
            className={classNames(
              'h-[16px] w-[16px] flex justify-center items-center border-[1px] rounded-[50%] bg-primary-lightest',
              checked() ? 'border-primary-medium' : 'border-primary-light'
            )}
          >
            {checked() && (
              <span className="h-[8px] w-[8px] bg-primary-medium rounded-[50%]" />
            )}
          </span>
        </div>
        <div className="ml-2 w-full ">
          <div className="flex items-center justify-evenly lg:justify-start text-gray-dark font-normal text-base">
            <span>{label}</span>

            <span
              id="icon"
              className="flex justify-center mr-2 xl:mr-6 ml-auto w-8"
            >
              {icon && icon()}
            </span>
          </div>
        </div>
      </div>
      <div className="block lg:hidden">{checked() && <>{children}</>}</div>
    </div>
  );
};
