import {
  forwardRef,
  ReactFragment,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import ArrowRightSVG from '@assets/icons/arrow-right.svg';
import CircleCheckedSVG from '@assets/icons/circle-checked.svg';
import classNames from 'classnames';

interface AccordionForwardRef {
  open(): void;
  close(): void;
}

interface AccordionProps {
  checked?: boolean;
  label: string;
  value?: string;
  children?: ReactNode | ReactFragment;
  hidden?: boolean;
  onOpen?(): void;
  id?: string;
}

export type AccordionRef = Ref<AccordionForwardRef>;

const AccordionComponent = (props: AccordionProps, ref: AccordionRef) => {
  const { onOpen } = props;
  const [collapsed, setCollapsed] = useState(false);
  const collapseControl = useRef(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setCollapsed(false);
    },
    close: () => {
      setCollapsed(true);
    }
  }));

  const clickHandler = useCallback(() => {
    setCollapsed(state => !state);
  }, []);

  useEffect(() => {
    if (!collapsed && !collapseControl.current) {
      onOpen?.();
      collapseControl.current = true;
    } else if (collapsed && collapseControl.current) {
      collapseControl.current = false;
    }
  }, [collapsed, onOpen]);

  return (
    <div className="w-full h-auto">
      <div
        id={props.id ?? 'content-label'}
        onClick={clickHandler}
        className={classNames(
          'w-[inherit] h-[inherit] cursor-pointer flex justify-between px-2 lg:px-4 lg:py-2 border border-gray-light rounded-md',
          {
            hidden: props.hidden,
            'rounded-b-none': !collapsed
          }
        )}
      >
        <div className="flex">
          <div className="flex justify-center items-center w-fit-content p-2">
            <div className="mr-2">
              <span className="font-sans font-bold normal text-base md:text-lg text-primary-medium">
                {props.label}
              </span>
            </div>
            {props.value && (
              <div>
                <div className="relative top-[1.5px]">
                  <CircleCheckedSVG color="#66C2CC" />
                </div>
              </div>
            )}
          </div>
          {props.value && (
            <span className="hidden lg:flex justify-center ml-12 items-center w-fit-content font-sans font-normal normal text-sm text-gray-dark">
              {props.value}
            </span>
          )}
        </div>
        <div className="h-[inherit] flex items-center p-4">
          <ArrowRightSVG
            className={collapsed ? 'rotate-90' : 'rotate-[-90deg]'}
            color="#244ACF"
            height="16px"
            width="16px"
          />
        </div>
      </div>

      <div
        className={classNames(
          'w-full border border-t-0 border-gray-light rounded-b',
          collapsed ? 'hidden' : 'block'
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

export const Accordion = forwardRef<AccordionForwardRef, AccordionProps>(
  AccordionComponent
);
